<template>
	<GoodTable />
</template>

<script>
// import BSTable from './BSTable'
import GoodTable from './GoodTable'

export default {
	name: 'ExternalOrders',
	components: { GoodTable },
}
</script>